    angular.module('fingerink')
    .factory('signatureService', ['$http', 'session', function ($http, session) {
        var sdo = {
            createFirma: function (firma) {
                return $http({
                    method: 'POST',
                    url: 'signatures',
                    crossDomain: true,
                    data: firma
                });
            },

            getFirmasTotales: function () {
                return $http({
                    method: 'get',
                    url: 'signatures/getTotalSignatures',
                    crossDomain: true
                });
            },


            getFirmasStatus: function () {
                return $http({
                    method: 'get',
                    url: 'getSignaturesStatus',
                    crossDomain: true
                });
            },

            getSignerIdCardUrls: function(id){
                return $http({
                    method: 'get',
                    url: 'signatures/signerIdCardImages/'+id,
                    crossDomain: true
                });
            },

            getFirmasMovilTotales: function () {
                return $http({
                    method: 'get',
                    url: 'getMobileSignatures',
                    crossDomain: true
                });
            },

            uploadFirmaFile: function (file, id, token) {
                var fd = new FormData();
                fd.append('file', file);
                fd.append('token', token);
                fd.append('id', id);
                var promise = $http.post('signatures/file/', fd, {
                    withCredentials: false,
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                    crossDomain: true
                });
                return promise;
            },


            retrieveFirmaFiles: function (campo) {
                return $http({
                    method: 'get',
                    url: 'signatures/getSignatureFile/' + campo,
                    crossDomain: true
                });
            },


            resendFirma: function (idFirma, idFirmante) {
                return $http({
                    method: 'POST',
                    url: 'signatures/sendemail/' + idFirma + '/' + idFirmante,
                    crossDomain: true
                });
            },

            changeSigner: function (idFirma, idFirmante, signer) {
                return $http({
                    method: 'POST',
                    url: 'signatures/changeSigner/' + idFirma + '/' + idFirmante,
                    data:signer,
                    crossDomain: true
                });
            },






            retrieveFirmas: function (params) {
                return $http({
                    method: 'get',
                    url: 'signatures' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            retrieveFirma: function (id) {
                return $http({
                    method: 'get',
                    url: 'signatures/' + id,
                    crossDomain: true
                });
            },

            deleteSignature: function (id) {
                return $http({
                    method: 'DELETE',
                    url: 'signatures/' + id,
                    crossDomain: true
                });
            },
            
            retrieveFirmaByToken: function (token) {
                return $http({
                    method: 'get',
                    url: 'signatures/byToken/' + token,
                    crossDomain: true
                });
            },
            firmarByToken: function (token, campos) {
                return $http({
                    method: 'post',
                    url: 'signatures/signByToken/' + token,
                    crossDomain: true,
                    data: campos
                });
            },


            signIdByToken: function (token, idCard) {
                return $http({
                    method: 'post',
                    url: 'signatures/signIdByToken/' + token,
                    crossDomain: true,
                    data: idCard
                });
            },


            rechazarByToken: function (token, campos) {
                return $http({
                    method: 'post',
                    url: 'signatures/rejectByToken/' + token,
                    crossDomain: true,
                    data: campos
                });
            },

            acceptByToken: function (token) {
                return $http({
                    method: 'post',
                    url: 'signatures/acceptByToken/' + token,
                    crossDomain: true
                });
            },



            //////////////////////////////////
            // DESCARGA DE IMAGENES  ///////
            //////////////////////////////////

            getTokenURL: function (token, file, page) {
                return $http({
                    cache: true,
                    method: 'GET',
                    url: 'signatures/URLTokenFile/' + file + ((page != null) ? '/' + page : "") + "?token=" + token,
                    crossDomain: true
                });
            },

            getURL: function (file, page) {
                return $http({
                    cache: false,
                    method: 'GET',
                    url: 'signatures/URLFile/' + file + ((page != null) ? '/' + page : ""),
                    crossDomain: true
                });
            },

            getSignedURL: function (file, token) {
                return $http({
                    cache: true,
                    method: 'GET',
                    url: 'signatures/URLSignatureSignedFile/' + file,
                    crossDomain: true
                });

            },
            
            getTokenDownloadURL: function (id) {
                return $http({
                    cache: false,
                    method: 'GET',
                    url: 'signatures/downloadToken/' + id,
                    crossDomain: true
                });

            },
            
            getTokenDownloadURLbyToken: function (id) {
                return $http({
                    cache: false,
                    method: 'GET',
                    url: 'signatures/downloadTokenbyToken/' + id,
                    crossDomain: true
                });

            },
            
            







            //////////////////////////////////
            // DRAFTS DE FIRMAS   ////////////
            //////////////////////////////////

            createFirmaDraft: function (file, documento) {
                var fd = new FormData();
                fd.append('file', file);
                fd.append('sign', JSON.stringify(documento));
                var promise = $http.post('signatures/', fd, {
                    withCredentials: false,
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined,
                        'Authorization': 'Bearer ' + session.get().token
                    },
                    crossDomain: true
                });
                return promise;
            },
            createFirmaDraftFromURL: function (documento) {
                return $http({
                    method: 'POST',
                    url: 'signatures/fromURL',
                    crossDomain: true,
                    data: documento
                });
            },

            createFirmaDraftFromHTML: function (documento) {
                return $http({
                    method: 'POST',
                    url: 'signatures/fromHTML',
                    crossDomain: true,
                    data: documento
                });
            },

                
            //Categorias de la firma
            createFirmaCategoria: function (firmac) {
                return $http({
                    method: 'POST',
                    url: 'signatures/categories/',
                    crossDomain: true,
                    data: firmac
                });
            },
            getFirmaCategories: function (params) {
                return $http({
                    method: 'GET',
                    url: 'signatures/categories/' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            deleteFirmaCategories: function (id) {
                return $http({
                    method: 'DELETE',
                    url: 'signatures/categories/' + id,
                    crossDomain: true
                });
            },
            
            moveFirmatoCategory: function (grupo) {
                return $http({
                    method: 'PUT',
                    url: 'signatures/movetocategory/' + grupo.id,
                    crossDomain: true,
                    data: grupo
                });
            }          
                        

        };
        return sdo;
}]);
